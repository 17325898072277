<template>
  <div>
    <div id="container" />
    <div class="canvas-container">
      <div id="canvas-output" />
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
export default {
  data() {
    return {
      canvas: null,
      cube: null,
      camera: null,
      scene: null,
      renderer: null,
      controls: null
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    formatTooltip(val) {
      return val
    },
    // 初始化
    async init() {
      await this.addCanvas()
      this.createScene() // 创建场景

      this.createLight() // 创建光源
      this.createCamera() // 创建相机
      this.createRender() // 创建渲染器

      this.createModels() // 创建模型
      this.createControls() // 创建控件对象
      this.render() // 渲染
    },
    //创建canvas绘图板
    addCanvas() {
    //   const publicPath = process.env.BASE_URL
    //   this.canvas = document.createElement('canvas')
    //   document.getElementById('canvas-output').appendChild(this.canvas)
    //   $('canvas-output').literallycanvas({
    //     imageURLPrefix: `${publicPath}libs/literally/img`
    //   })
    },
    // 创建场景
    createScene() {
      this.scene = new THREE.Scene()
    },

    // 创建模型
    createModels() {
      this.cube = this.createMesh(new THREE.BoxGeometry(10, 10, 10))
      this.cube.position.x = 2
      this.scene.add(this.cube)
    },

    createMesh(geom) {
      const canvasMap = new THREE.Texture(this.canvas) //从canvas上获取纹理
      const mat = new THREE.MeshPhongMaterial({
        color: 0x265123,
        map: canvasMap
      }) //使用canvas纹理贴图创建材质
      const mesh = new THREE.Mesh(geom, mat)

      return mesh
    },

    // 创建光源
    createLight() {
      // 环境光
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.1) // 创建环境光
      this.scene.add(ambientLight) // 将环境光添加到场景

      const light = new THREE.DirectionalLight() // 创建平行光
      light.position.set(10, 100, 80)
      this.scene.add(light)

      const spotLight = new THREE.SpotLight(0xffffff) // 创建聚光灯
      spotLight.position.set(0, 30, 30)
      spotLight.intensity = 1.2
      this.scene.add(spotLight)
    },
    // 创建相机
    createCamera() {
      const element = document.getElementById('container')
      const width = element.clientWidth // 窗口宽度
      const height = element.clientHeight // 窗口高度
      const k = width / height // 窗口宽高比
      // PerspectiveCamera( fov, aspect, near, far )
      this.camera = new THREE.PerspectiveCamera(35, k, 0.1, 1000)

      this.camera.position.set(0, 12, 28) // 设置相机位置

      this.camera.lookAt(new THREE.Vector3(0, 0, 0)) // 设置相机方向
      this.scene.add(this.camera)
    },
    // 创建渲染器
    createRender() {
      const element = document.getElementById('container')
      this.renderer = new THREE.WebGLRenderer()
      this.renderer.setSize(element.clientWidth, element.clientHeight) // 设置渲染区域尺寸
      // this.renderer.shadowMap.enabled = true // 显示阴影
      // this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
      this.renderer.setClearColor(0xeeeeee, 1) // 设置背景颜色
      element.appendChild(this.renderer.domElement)
    },

    render() {
      this.cube.rotation.y += 0.01
      this.cube.rotation.x += 0.01
      this.cube.material.map.needsUpdate = true //更新使用canvas纹理贴图创建的材质
      this.renderer.render(this.scene, this.camera)
      requestAnimationFrame(this.render)
    },
    // 创建控件对象
    createControls() {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    }
  }
}
</script>
<style>
#container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.canvas-container {
  width: 300px;
  height: 300px;
  margin: auto;
  bottom: 20px;
  position: absolute;
}

#canvas-output {
  float: left;
  width: 300px;
  height: 300px;
}
</style>

